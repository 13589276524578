@import "../../../../../styles/variables.scss";

.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 35vw);
    grid-template-rows: repeat(5, 35vw);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-38%, -33%) rotate(45deg);
    gap: 0;
    transition:
        padding 0.5s ease-in-out,
        opacity 0.15s ease-in-out;
    padding-left: 0px;
    opacity: 1;
    overflow: hidden;
    gap: 4px;
    padding-left: 4px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(3, 45vw);
        grid-template-rows: repeat(5, 45vw);
        top: 70%;
        gap: 2px;
        padding: 2px;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 55vw);
        grid-template-rows: repeat(5, 55vw);
        top: 70%;
    }

    &_cyberpunk {
        .Future_light {
            background: radial-gradient(circle, $light_cyberpunk 40%, $bg-cyberpunk 70%);
            -webkit-animation: moveAround 9s infinite;
            animation: moveAround 9s infinite;
        }
        .block_1,
        .block_2,
        .block_3,
        .block_4,
        .block_5,
        .block_6 {
            span {
                opacity: 1;
                transition: opacity 0.5s ease-in-out;
            }
        }
    }

    &_future {
        .Future_light {
            background: radial-gradient(circle, $light_future 40%, $bg-future 70%);
            -webkit-animation: moveAround 9s infinite;
            animation: moveAround 9s infinite;
        }
    }
}

.transitionActive {
    opacity: 0.5;
    transition: opacity 0.15s ease-in-out;
}

.Future_light {
    position: absolute;
    width: 65vw;
    height: 65vw;
    opacity: 1;
    border-radius: 50%;
    z-index: -1;
}

@-webkit-keyframes moveAround {
    0% {
        transform: translate(50%, 50%);
    }
    20% {
        transform: translate(-40%, 120%);
    }
    40% {
        transform: translate(50%, 120%);
    }
    60% {
        transform: translate(0%, -15%);
    }
    80% {
        transform: translate(-40%, 120%);
    }
    100% {
        transform: translate(50%, 50%);
    }
}

@keyframes moveAround {
    0% {
        transform: translate(50%, 50%);
    }
    20% {
        transform: translate(-40%, 120%);
    }
    40% {
        transform: translate(50%, 120%);
    }
    60% {
        transform: translate(0%, -15%);
    }
    80% {
        transform: translate(-40%, 120%);
    }
    100% {
        transform: translate(50%, 50%);
    }
}

.block_1,
.block_2,
.block_3,
.block_4,
.block_5,
.block_6 {
    position: relative;
    overflow: hidden;

    span {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }
}

.block_1 {
    grid-area: span 2 / span 1 / auto / auto;
    background: $block1_future;

    span {
        background: $block1_cyberpunk;
    }
}

.block_2 {
    grid-area: span 2 / span 2 / auto / auto;
    background: $block2_future;

    span {
        background: $block2_cyberpunk;
    }
}

.block_3 {
    grid-area: span 1 / span 1 / auto / auto;
    background: $block3_future;

    span {
        background: $block3_cyberpunk;
    }
}

.block_4 {
    grid-area: span 1 / span 2 / auto / auto;
    background: $block4_future;

    span {
        background: $block4_cyberpunk;
    }
}

.block_5 {
    grid-area: span 1 / span 2 / auto / auto;
    background: $block5_future;

    span {
        background: $block5_cyberpunk;
    }
}

@-webkit-keyframes gradient {
    0% {
        background-position: 80% 0%;
    }

    50% {
        background-position: 20% 100%;
    }

    100% {
        background-position: 80% 0%;
    }
}

@keyframes gradient {
    0% {
        background-position: 80% 0%;
    }

    50% {
        background-position: 20% 100%;
    }

    100% {
        background-position: 80% 0%;
    }
}

@-webkit-keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
