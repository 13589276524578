@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: rotate(120deg);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 5%;
    &:hover {
        .nextThemePreview {
            width: 130%;
            height: 130%;
        }
    }
}

.ellipse {
    width: 38px;
    height: 38px;
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease-out;
    &_future {
        background: $gradient-button-future;
    }
    &_cyberpunk {
        background: $darkest-grey;
    }
}

.textContainer {
    transform: scale(0.6);
}

.text {
    letter-spacing: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-animation: 5s rotating linear infinite;
    animation: 5s rotating linear infinite;
    overflow: visible;
    &_future {
        fill: $dark-purple;
    }

    &_cyberpunk {
        fill: $darkest-grey;
    }

    @include font-600;
}

.nextThemePreview {
    width: 0;
    height: 0;
    position: absolute;
    border-radius: 50%;
    transition: all 0.3s ease-out;

    &_future {
        background-color: $bg-cyberpunk;
    }

    &_cyberpunk {
        background-color: $bg-future;
    }
}

@include tablet {
    .container {
        right: 4%;
    }
}

@include laptop {
    .ellipse {
        width: 62px;
        height: 62px;
    }

    .textContainer {
        transform: scale(1);
    }

    .text {
        -webkit-animation: 5s rotating linear infinite;
        animation: 5s rotating linear infinite;
    }
}

@-webkit-keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
