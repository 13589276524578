@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    width: 100%;
    min-height: 185px;
    height: 100%;
    flex: 1;
    z-index: 1;
    position: relative;
    &_empty {
        border-radius: 30px;
        background: linear-gradient(0deg, rgba(252, 252, 254, 0.08), rgba(252, 252, 254, 0.08));
        border: 2px dashed rgba($color: $text-light, $alpha: 0.5);
        box-sizing: border-box;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.label {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: rgba($color: $text-light, $alpha: 0.1);
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 8px;
    pointer-events: none;
    color: $text-light;
    font-size: 20px;
    @include font-400;

    p:first-of-type {
        display: none;
    }

    svg {
        width: 25px;
    }
}

.doc,
.arrow {
    position: absolute;
    pointer-events: none;
}

.doc {
    top: -15px;
    left: -25px;
    width: 70px;
}

.arrow {
    top: -27px;
    left: 0;
    width: 104px;
}

.fileInfo {
    display: flex;
    flex-direction: column;
    gap: 55px;
}

.formatIconContainer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.formatIcon {
    height: 84px;
    position: absolute;
}

.formatName {
    font-size: 9px;
    color: $text-light;
    text-transform: uppercase;
    position: absolute;
    transform: translate(-20px, 13px);
    @include font-400;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@include laptop {
    .container {
        min-height: 302px;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2px;
        pointer-events: none;
        color: $text-light;
        font-size: 20px;
        @include font-300;

        p:first-of-type {
            display: block;
        }

        svg {
            width: 40px;
            margin-bottom: 10px;
        }
    }

    .doc {
        top: 30px;
        left: -50px;
        width: 100px;
    }

    .arrow {
        top: -38px;
        left: 4px;
        width: 192px;
    }

    .fileInfo {
        width: 90%;
        gap: 74px;
    }

    .formatIconContainer {
        height: 116px;
    }

    .formatIcon {
        height: 146px;
    }

    .formatName {
        font-size: 16px;
        transform: translate(-32px, 24px);
    }

    .formContainer {
        &_top {
            width: 100%;
            justify-content: flex-start;
            gap: 20px;
        }
    }
}
