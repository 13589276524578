@import "../../../styles/variables.scss";

.BackWrapper,
.FrontWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
}

.BackWrapper {
    background: $darkest-dust;
}

.FrontWrapper {
    z-index: 1;
}

.container {
    position: absolute;
    right: 4%;
    bottom: 5%;
    height: 100px;
    width: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BackMode {
    width: 350vw;
    height: 350vw;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    -webkit-animation: 1.8s fade ease-out forwards;
    animation: 1.8s fade ease-out forwards;
    display: flex;
    flex: 0 0 auto;
    opacity: 1;
    @media (max-width: 768px) {
        width: 260vh;
        height: 260vh;
        -webkit-animation-duration: 1.2s;
        animation-duration: 1.2s;
    }
}

.FrontMode {
    width: 350vw;
    height: 350vw;
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    -webkit-animation: 1.8s fadeWithOpacity ease-out forwards;
    animation: 1.8s fadeWithOpacity ease-out forwards;
    display: flex;
    flex: 0 0 auto;
    opacity: 1;
    @media (max-width: 768px) {
        width: 260vh;
        height: 260vh;
        -webkit-animation-duration: 1.2s;
        animation-duration: 1.2s;
    }
}

.mode_future {
    background: $bg-future;
}

.mode_cyberpunk {
    background: $bg-cyberpunk;
}

@-webkit-keyframes fade {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
@keyframes fade {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@-webkit-keyframes fadeWithOpacity {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    40% {
        opacity: 0.3;
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes fadeWithOpacity {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    40% {
        opacity: 0.3;
    }
    50% {
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
