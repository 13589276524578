@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    display: flex;
    color: $text-light;
    font-size: 14px;
    @include font-500;
}

.groupItem {
    margin-right: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.input {
    appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid $text-light;
    transition: all 0.3s ease-in-out;
    outline: none;
    margin: 0 8px 0 0;
    cursor: pointer;

    &:checked {
        background: $text-light;
    }

    &_future {
        &:checked {
            border: 5px solid $bg-future;
        }
    }

    &_cyberpunk {
        &:checked {
            border: 6px solid $pink-primary;
        }
    }
}

label {
    cursor: pointer;
}
