// BREAK POINTS
$mobile-sm-width: 320px;
$mobile-base-width: 375px;
$mobile-md-width: 576px;
$mobile-lg-width: 768px;
$tablet-width: 992px;
$laptop-width: 1200px;
$desktop-width: 1920px;

// COLORS ITEMS
$folder-border: #d9d9d9;

//GRID COLORS
$block1_future: linear-gradient(20deg, rgba(97, 97, 97, 1) 0%, rgba(72, 72, 72, 1) 75%);
$block1_cyberpunk: linear-gradient(20deg, rgba(210, 15, 255, 1) 0%, rgba(17, 18, 255, 1) 75%);

$block2_future: #d6d6d6;
$block2_cyberpunk: #2af9ff;

$block3_future: linear-gradient(140deg, rgba(46, 46, 46, 1) 0%, rgba(33, 33, 33, 1) 100%);
$block3_cyberpunk: linear-gradient(140deg, rgba(48, 14, 160, 1) 0%, rgba(11, 6, 106, 1) 100%);

$block4_future: #292929;
$block4_cyberpunk: #0b1b71;

$block5_future: #525252;
$block5_cyberpunk: #d608ab;

$light_future: #00fbff;
$light_cyberpunk: #ff0066;

// PERSONAL BLOCK

$personal1_future: #2b2b2b;
$personal2_future: #494949;
$personal3_future: #7e7e7e;

$personal2_cyberpunk: #ca10ff;

// COLORS BACKGROUND
$bg-future: #000000;
$bg-cyberpunk: #0b0859;

// COLORS TEXT
$text-hero: #fffdf6;
$text-dark: #000000;
$text-light: #ffffff;

// COLORS BUTTON
$button-bg-future: #00f8ff;
$button-bg-future-hover: #00959a;
$button-bg-cyberpunk: rgba(
    $color: #4641ff,
    $alpha: 0.8
);

// COLORS PRIMARY
$cyan-primary: #00c6cc;
$pink-primary: #ce08a3;
$purple-primary: #980cfb;
$yellow-primary: #ffc10e;

// COLORS GREY
$default-grey: #1b1b1b;
$darkest-grey: #1e1e1e;
$dark-grey: #252525;
$light-grey: #4b4b4b;
$lightest-grey: #5a5a5a;

// COLORS DUST
$darkest-dust: #e5e5e5;
$dark-dust: #e9eaea;
$light-dust: #f2f3f5;
$lightest-dust: #f6f8fa;

// COLORS PURPLE
$purple: #740fff;
$darkest-purple: #2f0f9e;
$asd: #100760;
$dark-purple: #9c0cff;
$light-purple: #a563ff;
$lightest-purple: #ceaaff;

// COLORS BLUE
$blue: #0712ff;
$lightest-blue: #3b42ea;
$light-blue: #143cb8;
$dark-blue: #110d7e;
$darkest-blue: #0c0757;

$gradient-button-future: linear-gradient(
    127.22deg,
    #d108a2 -0.39%,
    #d00ffe 34.83%,
    #8c10ff 65.31%,
    #0313ff 86.34%,
    #190d8b 100.53%
);
