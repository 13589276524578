@import "./mixins.scss";
@import "./variables.scss";

* {
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
}

html,
body {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    font-family: "Inter-500";
}

button,
input,
textarea {
    border: 0;
    outline: none;
    padding: 0;
    margin: 0;
    color: inherit;
    font-family: inherit;
    background-color: transparent;
}

button {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:disabled {
        cursor: not-allowed;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    -webkit-margin-before: 0;
    margin-block-start: 0;
    -webkit-margin-after: 0;
    margin-block-end: 0;
}

@font-face {
    font-family: "Inter-100";
    src:
        local("Inter"),
        url("../fonts/Inter/Inter-Thin.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "Inter-200";
    src:
        local("Inter-Pro"),
        url("../fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}

@font-face {
    font-family: "Inter-300";
    src:
        local("Inter"),
        url("../fonts/Inter/Inter-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Inter-400";
    src:
        local("Inter"),
        url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Inter-500";
    src:
        local("Inter"),
        url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Inter-600";
    src:
        local("Inter"),
        url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Inter-700";
    src:
        local("Inter"),
        url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Inter-800";
    src:
        local("Inter"),
        url("../fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
    font-weight: 800;
}

@font-face {
    font-family: "Inter-900";
    src:
        local("Inter"),
        url("../fonts/Inter/Inter-Black.ttf") format("truetype");
    font-weight: 900;
}