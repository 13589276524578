@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 56px;
    padding-top: 38px;
    padding-right: 16px;
    padding-bottom: 38px;
    padding-left: 16px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.header {
    width: 100%;
    max-width: 615px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 18px;
    box-sizing: border-box;
    background: rgba($color: $text-light, $alpha: 0.26);
    position: relative;
}

.tabHeaderItem {
    text-align: center;
    font-size: 14px;
    color: $text-light;
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

.headerActiveBackground {
    height: calc(100% - 14px);
    background: rgba($color: $text-light, $alpha: 0.26);
    position: absolute;
    border-radius: 10px;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
}

.tabContent {
    width: 100%;
    opacity: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    pointer-events: none;

    &_active {
        opacity: 1;
        position: relative;
        transition: all 0.3s ease-in-out;

        .title {
            top: 0;
        }

        .description {
            opacity: 1;
            transform: scale(1);
        }

        .image {
            opacity: 1;
            top: 0;
        }
    }
}

.text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.title {
    font-size: 24px;
    color: $text-light;
    text-align: center;
    position: relative;
    top: -15px;
    transition: top 0.3s ease-in-out 0.3s;
    @include font-600;
}

.description {
    font-size: 16px;
    color: $text-light;
    text-align: center;
    width: 90%;
    line-height: 32px;
    opacity: 0;
    transform: scale(0.9);
    transition:
        opacity 0.3s ease-in-out 0.6s,
        transform 0.3s ease-in-out 0.9s;
    @include font-400;
}

.image {
    max-width: 96%;
    position: relative;
    top: 15px;
    left: 10px;
    opacity: 0;
    transition:
        opacity 0.3s ease-in-out 1.2s,
        top 0.3s ease-in-out 1.5s;
}

@include tablet {
    .content {
        gap: 30px;
    }
}

@include laptop {
    .content {
        gap: 40px;
    }

    .tabHeaderItem {
        font-size: 20px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .headerActiveBackground {
        background: rgba($color: $text-dark, $alpha: 0.15);
    }

    .title {
        font-size: 36px;
    }

    .description {
        font-size: 18px;
        width: 70%;
    }

    .image {
        max-width: 96%;
        height: 70vh;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
    }

    .text {
        gap: 24px;
    }

    .tabContent {
        gap: 20px;
    }
}
