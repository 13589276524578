@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    width: 100%;
    height: 56px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    color: $text-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-right: 16px;
    padding-bottom: 0;
    padding-left: 16px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    &_active {
        background: rgba($color: $bg-future, $alpha: 0.5);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
    }
}

.logo {
    width: 75px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    &_visible {
        opacity: 1;
        pointer-events: all;
    }
    & g g:nth-child(2) {
        transform-origin: 7% 25%;
    }
}

.logo_animation g g:nth-child(2) {
    -webkit-animation: rotate_and_scale 1s alternate 1;
    animation: rotate_and_scale 1s alternate 1;
    transform-origin: 7% 25%;
}

@-webkit-keyframes rotate_and_scale {
    0% {
        transform: rotate(0) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(0.3);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes rotate_and_scale {
    0%,
    100% {
        transform: rotate(0) scale(1);
    }
    50% {
        transform: rotate(360deg) scale(0.3);
    }
}

.links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.loginContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.loginContainer a:last-child {
    width: 100px;
    text-align: center;
}

@include laptop {
    .container {
        height: 80px;
        padding-top: 0;
        padding-right: 40px;
        padding-bottom: 0;
        padding-left: 40px;
    }

    .logo {
        width: 134px;
    }

    .links {
        width: auto;
        gap: 40px;
    }

    .loginContainer,
    .links {
        gap: 24px;
    }

    .loginContainer a:last-child {
        width: 152px;
        text-align: center;
    }
}
