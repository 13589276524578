@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
    &:hover .circle {
        transition: all 0.7s linear;
        opacity: 0.3;
        transform: scale(150);
    }
}

.field {
    width: 100%;
    outline: 0;
    padding-top: 16px;
    padding-right: 40px;
    padding-bottom: 16px;
    padding-left: 20px;
    background: linear-gradient(0deg, rgba(252, 252, 254, 0.08), rgba(252, 252, 254, 0.08));
    border: 1px solid rgba($color: $text-light, $alpha: 0.5);
    border-radius: 16px;
    box-sizing: border-box;
    color: rgba($color: $text-light, $alpha: 0.8);
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    position: relative;
    @include font-400;

    &_withIcon {
        padding-right: 60px;
    }

    &_withButton {
        padding-top: 9px;
        padding-right: 110px;
        padding-bottom: 9px;
        padding-left: 12px;
        border-radius: 30px;
    }

    &::-webkit-input-placeholder {
        color: rgba($color: $text-light, $alpha: 0.8);
    }

    &::-moz-placeholder {
        color: rgba($color: $text-light, $alpha: 0.8);
    }

    &:-ms-input-placeholder {
        color: rgba($color: $text-light, $alpha: 0.8);
    }

    &::-ms-input-placeholder {
        color: rgba($color: $text-light, $alpha: 0.8);
    }

    &::placeholder {
        color: rgba($color: $text-light, $alpha: 0.8);
    }

    &:disabled {
        cursor: not-allowed;
        background: linear-gradient(0deg, rgba(252, 252, 254, 0.2), rgba(252, 252, 254, 0.2));
    }
}

.iconContainer {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translate(0, -50%);
    cursor: pointer;

    &:hover {
        .iconBg {
            opacity: 1;
        }
    }
}

.iconBg {
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 50%;
    position: absolute;
    background: linear-gradient(0deg, rgba(252, 252, 254, 0.1), rgba(252, 252, 254, 0.1));
    transition: all 0.3s ease-in-out;
}

.icon {
    position: relative;
    width: 18px;
}

.button {
    position: absolute;
    top: 0;
    right: -2px;
    border-radius: 30px;
    z-index: 2;
}

.circle {
    position: absolute;
    border-radius: 50%;
    left: 25%;
    top: 50%;
    width: 1%;
    padding-bottom: 1%;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 0;
    &_future {
        background: $cyan-primary;
    }
    &_cyberpunk {
        background: $pink-primary;
    }
}

@include laptop {
    .field {
        &_withButton {
            padding-top: 14px;
            padding-right: 160px;
            padding-bottom: 14px;
            padding-left: 12px;
            border-radius: 30px;
        }
    }
}
