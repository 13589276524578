@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.wrapper {
    position: relative;
    height: 330px;
    width: 100%;
}

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 18px;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    &_front {
        transform: perspective(400px) rotateY(0);
    }
    &_back {
        transform: perspective(400px) rotateY(180deg);
    }
    &_cyberpunk {
        background-color: $block5_cyberpunk;
        .circle {
            background: $button-bg-future-hover;
        }
    }
    &_future {
        background-color: $block4_future;
        .circle {
            background: $light_future;
        }
    }
}

.wrapper:nth-child(2) {
    .container_cyberpunk {
        background-color: $block2_cyberpunk;
        .title,
        .description {
            color: $text-dark;
            transition: color 0.5s ease-in-out;
        }
    }
}

.container_front_isExpanded {
    transform: perspective(400px) rotateY(-180deg);
}

.container_back_isExpanded {
    transform: perspective(400px) rotateY(0deg);
}

.title {
    margin: 24px 48px 36px 42px;
    font-size: 24px;
    color: $text-light;
    line-height: 25px;
    -webkit-animation: 0.5s showWithOpacity ease-in-out forwards;
    animation: 0.5s showWithOpacity ease-in-out forwards;
    transition: color 0.5s ease-in-out;
    @include font-600;
    position: relative;
    z-index: 1;
}

.collapsibleContent {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
}

.description {
    width: 74%;
    position: absolute;
    top: -22px;
    left: 42px;
    font-size: 16px;
    color: $text-light;
    line-height: 24px;
    transition: all 0.5s ease-in-out;
    z-index: 1;
    @include font-400;
}

.image {
    position: relative;
    z-index: 1;
}

.collapseIconContainer {
    position: absolute;
    background: $bg-future;
    width: 38px;
    height: 38px;
    bottom: 20px;
    right: 26px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    z-index: 1;
    &:hover {
        background: rgba($color: $bg-future, $alpha: 0.6);
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
    }
}

.container_back_isExpanded .collapseIconContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .collapseIconItem {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: relative;
        left: 0;
        top: 0;
        transition: height 0.2s ease-in-out;
        &:nth-child(1) {
            transform: translate(0, 0);
        }
        &:nth-child(2) {
            display: block;
        }
        &:nth-child(3) {
            transform: translate(-0, 0) rotate(0);
        }
    }
}

.collapseIconItem {
    width: 2px;
    height: 10px;
    background: $text-light;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: height 0.2s ease-in-out;
    &:nth-child(1) {
        transform: translate(-50%, -50%);
    }
    &:nth-child(2) {
        display: none;
    }
    &:nth-child(3) {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

@-webkit-keyframes rotate {
    100% {
        transform: rotate(1turn);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(1turn);
    }
}

.frame {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 18px;
    overflow: hidden;
    padding: 2rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &::before {
        content: "";
        position: absolute;
        z-index: -2;
        left: -50%;
        top: -50%;
        width: 200%;
        height: 200%;
        background-repeat: no-repeat;
        background-size:
            50% 50%,
            50% 50%;
        background-position:
            0 0,
            100% 0,
            100% 100%,
            0 100%;

        -webkit-animation:
            rotate 4s linear infinite,
            opacityChange 3s ease-in-out infinite;
        animation:
            rotate 4s linear infinite,
            opacityChange 3s ease-in-out infinite;
    }

    &::after {
        content: "";
        position: absolute;
        z-index: -1;
        left: 3px;
        top: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 28px;
        @media (max-width: 1200px) {
            border-radius: 14px;
        }
    }
    &_cyberpunk {
        &::after {
            background-color: $block5_cyberpunk;
            transition: all 0.5s ease-in-out;
        }
        &::before {
            background-color: $block5_cyberpunk;
            background-image: linear-gradient($light_future, $block5_cyberpunk);
        }
    }
    &_future {
        &::after {
            background-color: $block4_future;
            transition: all 0.5s ease-in-out;
        }
        &::before {
            background-color: $block4_future;
            background-image: linear-gradient($light_future, $block4_future);
        }
    }
}

.wrapper:nth-child(2) {
    .container_cyberpunk {
        background-color: $block2_cyberpunk;
        .frame {
            &_cyberpunk {
                &::after {
                    background-color: $block2_cyberpunk;
                    transition: all 0.5s ease-in-out;
                }
                &::before {
                    background-color: $block2_cyberpunk;
                    background-image: linear-gradient($light_cyberpunk, $block5_cyberpunk);
                }
            }
        }
    }
}

.circle {
    position: absolute;
    border-radius: 50%;
    right: 11%;
    bottom: 12%;
    width: 2%;
    padding-bottom: 2%;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 1;
}

.circle.active {
    transition: all 0.5s linear;
    opacity: 0.4;
    transform: scale(140);
}

@-webkit-keyframes opacityChange {
    0%,
    100% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
}

@keyframes opacityChange {
    0%,
    100% {
        opacity: 0;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    75% {
        opacity: 0;
    }
}

@include tablet {
    .wrapper {
        height: 550px;
    }
}

@include laptop {
    .container {
        border-radius: 30px;
    }

    .title {
        margin: 45px 40px 64px 76px;
        font-size: 36px;
        color: $text-light;
        line-height: 46px;
    }

    .description {
        width: 76%;
        top: -42px;
        left: 75px;
        font-size: 18px;
        line-height: 32px;
    }

    .collapseIconContainer {
        width: 70px;
        height: 70px;
        bottom: 38px;
        right: 44px;
    }

    .collapseIconItem {
        width: 2px;
        height: 20px;
    }
}

@-webkit-keyframes showWithOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes showWithOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
