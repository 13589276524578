@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    margin-top: 40px;
    width: 100%;
    height: 200px;
    position: relative;
    transition: background-color 0.8s ease-in-out;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &_cyberpunk {
        background-color: $light-blue;
        .grid {
            & div:nth-child(1) {
                background-color: $cyan-primary;
                margin-right: 3px;
            }
            & div:nth-child(2) {
                background-color: $dark-blue;
            }
        }
    }
    &_future {
        background-color: $default-grey;
        .grid {
            & div:nth-child(1) {
                background-color: $block2_future;
                margin-right: 3px;
            }
            & div:nth-child(2) {
                background-color: $block5_future;
            }
        }
    }
    & h4 {
        font-style: normal;
        font-weight: 650;
        font-size: 24px;
        line-height: 38px;
        text-align: center;
        color: #ffffff;
        position: relative;
        z-index: 1;
        font-family: "Inter-600";
        padding: 0 30px;
    }
}

.grid {
    width: 90vw;
    height: 90vw;
    display: flex;
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 50%;
    transition: all 0.5s ease-in-out;
    transform: translate(-7%, -49%) rotate(106deg);
    padding-bottom: 4px;
    div {
        margin: 0;
        width: 100%;
        height: 100%;
        transition: all 0.6s ease-in-out;
    }
}

.future_light {
    position: absolute;
    width: 70vw;
    height: 70vw;
    opacity: 1;
    border-radius: 50%;
    z-index: -1;
    background: radial-gradient(circle, $light_future 0%, $default-grey 70%);
    -webkit-animation: moveAround 5s infinite;
    animation: moveAround 5s infinite;
}

.container_cyberpunk .future_light {
    background: radial-gradient(circle, $light_cyberpunk 0%, $light-blue 70%);
}

@-webkit-keyframes moveAround {
    0% {
        transform: translate(25%, 50%);
    }
    20% {
        transform: translate(25%, 75%);
    }
    40% {
        transform: translate(50%, 75%);
    }
    60% {
        transform: translate(25%, 35%);
    }
    80% {
        transform: translate(50%, 75%);
    }
    100% {
        transform: translate(25%, 50%);
    }
}

@keyframes moveAround {
    0% {
        transform: translate(50%, 50%);
    }
    20% {
        transform: translate(-40%, 120%);
    }
    40% {
        transform: translate(50%, 120%);
    }
    60% {
        transform: translate(0%, -15%);
    }
    80% {
        transform: translate(-40%, 120%);
    }
    100% {
        transform: translate(50%, 50%);
    }
}

@include tablet {
    .container {
        width: 100%;
        height: 400px;
        & h4 {
            font-style: normal;
            font-weight: 650;
            font-size: 24px;
            line-height: 56px;
            font-size: 48px;
        }
    }
}
