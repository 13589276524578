@import "../../../styles/variables.scss";

.container {
    position: relative;
    height: 100%;
}

.toast {
    position: fixed;
    z-index: 999;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 10px);
    background: rgba($color: #000000, $alpha: 0.8);
    color: $text-light;
    padding-top: 10px;
    padding-right: 40px;
    padding-bottom: 10px;
    padding-left: 40px;
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    &_active {
        opacity: 1;
        transform: translate(-50%, 0);
    }

    p {
        white-space: nowrap;
    }

    &.error {
        background: #FF8681;
    }
}