@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.link {
    font-size: 16px;
    color: $text-light;
    text-decoration: none;
    white-space: nowrap;
    @include font-400;
}
