@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding-top: 84px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    box-sizing: border-box;
}

.logo {
    width: 138px;
}

.nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.copyright {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: $text-light;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #4e4e4e;
    @include font-400;
}

@include laptop {
    .container {
        gap: 64px;
    }

    .content {
        display: grid;
        grid-template-columns: 6fr 7fr;
        align-items: start;
        padding-top: 28px;
        padding-left: 80px;
    }

    .logo {
        width: 130px;
    }

    .nav {
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 16px;
    }

    .copyright {
        justify-content: flex-start;
        padding-left: 80px;
        padding-top: 28px;
        padding-bottom: 28px;
        box-sizing: border-box;
    }
}
