@import "../../../../../styles/mixins.scss";
@import "../../../../../styles/variables.scss";

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 0;
    box-sizing: border-box;
    &_front {
        transform: perspective(400px) rotateY(0);
    }
    &_back {
        transform: perspective(400px) rotateY(180deg);
    }
    &_cyberpunk {
        background-color: $block5_cyberpunk;
        .circle {
            background: $button-bg-future-hover;
        }
    }
    &_future {
        background-color: $block4_future;
        .circle {
            background: $light_future;
        }
    }
}

.container_front_isExpanded {
    transform: perspective(400px) rotateY(-180deg);
}

.container_back_isExpanded {
    transform: perspective(400px) rotateY(0deg);
}

.collapseIconContainer {
    position: absolute;
    background: $bg-future;
    width: 38px;
    height: 38px;
    bottom: 20px;
    right: 26px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    z-index: 1;
    &:hover {
        background: rgba($color: $bg-future, $alpha: 0.6);
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
    }
}

.container_back_isExpanded .collapseIconContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .collapseIconItem {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: relative;
        left: 0;
        top: 0;
        transition: height 0.2s ease-in-out;
        &:nth-child(1) {
            transform: translate(0, 0);
        }
        &:nth-child(2) {
            display: block;
        }
        &:nth-child(3) {
            transform: translate(-0, 0) rotate(0);
        }
    }
}

.collapseIconItem {
    width: 2px;
    height: 10px;
    background: $text-light;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: height 0.2s ease-in-out;
    &:nth-child(1) {
        transform: translate(-50%, -50%);
    }
    &:nth-child(2) {
        display: none;
    }
    &:nth-child(3) {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

.advantage:nth-child(1) {
    .container_front {
        transform: perspective(400px) rotateY(0) rotateX(0);
    }
    .container_back {
        transform: perspective(400px) rotateY(180deg) rotateX(0);
    }
    .container_front_isExpanded {
        transform: perspective(400px) rotateY(-180deg) rotateX(0);
    }

    .container_back_isExpanded {
        transform: perspective(400px) rotateY(0deg) rotateX(0);
    }
}

.advantage {
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    min-height: 420px;
    & > div {
        background-color: #3d3d3d;
        border-radius: 30px;
    }
    &:first-of-type > div {
        background-color: #292929;
    }

    &_cyberpunk > div {
        background-color: $darkest-purple;
    }

    &_cyberpunk:first-of-type > div {
        background-color: $light-blue;
    }

    &:nth-of-type(1) {
        .imageContainer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding: 20px;
            position: relative;
            z-index: 1;
        }

        img {
            width: 95%;
        }
    }

    &:nth-of-type(2) {
        .imageContainer {
            padding-top: 24px;
            padding-bottom: 36px;
            position: relative;
            z-index: 1;
        }

        img {
            width: 100%;
        }
    }

    &:nth-of-type(3) {
        .imageContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 38px;
            position: relative;
            z-index: 1;
            padding-top: 10px;
        }

        img {
            width: 40%;
        }
    }
}

.text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 26px;
    padding-right: 20px;
    padding-bottom: 0;
    padding-left: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.title {
    color: $text-light;
    font-size: 24px;
    line-height: 40px;
    @include font-700;
}

.description {
    color: $text-light;
    font-size: 16px;
    line-height: 32px;
    @include font-400;
}

.circle {
    position: absolute;
    border-radius: 50%;
    right: 11%;
    bottom: 12%;
    width: 2%;
    padding-bottom: 2%;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 1;
}

.circle.active {
    transition: all 0.5s linear;
    opacity: 0.4;
    transform: scale(140);
}

@include tablet {
    .advantage {
        min-height: 450px;
        &:nth-of-type(1) {
            min-height: 600px;
        }
    }
}

@include laptop {
    .advantage:nth-child(1) {
        .container_front {
            transform: perspective(400px) rotateX(0) rotateY(0);
        }
        .container_back {
            transform: perspective(400px) rotateX(180deg) rotateY(0);
        }
        .container_front_isExpanded {
            transform: perspective(400px) rotateX(-180deg) rotateY(0);
        }

        .container_back_isExpanded {
            transform: perspective(400px) rotateX(0deg) rotateY(0);
        }
    }
    .advantage {
        border-radius: 34px;
        min-height: 500px;
        .text {
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
        }

        &:nth-of-type(1) {
            grid-area: first;
            & > div {
                padding-top: 40px;
                padding-bottom: 38px;
                padding-left: 72px;
                flex-direction: row;
                align-items: center;
                gap: 60px;
            }
            img {
                width: 100%;
                max-width: 700px;
                margin: 0 auto;
            }
        }

        &:nth-of-type(2) {
            grid-area: second;
            & > div {
                justify-content: space-between;
            }
            .text {
                padding-top: 48px;
                padding-right: 58px;
                padding-bottom: 0;
                padding-left: 84px;
            }
        }

        &:nth-of-type(3) {
            grid-area: third;
            & > div {
                gap: 60px;
            }
            .text {
                padding-top: 48px;
                padding-right: 58px;
                padding-bottom: 0;
                padding-left: 84px;
            }

            .imageContainer {
                width: 100%;
                justify-content: flex-end;
                padding-right: 80px;
                box-sizing: border-box;
            }

            img {
                width: 30%;
            }
        }
    }

    .title {
        font-size: 36px;
        line-height: 44px;
    }

    .description {
        font-size: 18px;
    }
    .collapseIconContainer {
        width: 70px;
        height: 70px;
        bottom: 28px;
        right: 34px;
    }

    .collapseIconItem {
        width: 2px;
        height: 20px;
    }
}
