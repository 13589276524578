@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    margin: 45px 16px 0;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    &::before,
    &::after {
        opacity: 0;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        transition: opacity 0.7s ease-in-out;
    }
    &::before {
        background: linear-gradient(180deg, $light-grey 1.75%, rgb(0, 0, 0, 0) 120%);
        @include mobile-md {
            background: linear-gradient(90.7deg, $light-grey 1.75%, rgb(0, 0, 0, 0) 99.3%);
        }
    }
    &::after {
        background: linear-gradient(180deg, $dark-purple 1.75%, rgb(0, 0, 0, 0) 120%);
        @include mobile-md {
            background: linear-gradient(90.7deg, $dark-purple 1.75%, rgb(0, 0, 0, 0) 99.3%);
        }
    }
    &_cyberpunk {
        &::before {
            opacity: 0;
        }
        &::after {
            opacity: 1;
        }
    }
    &_future {
        &::before {
            opacity: 1;
        }
        &::after {
            opacity: 0;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
    padding-right: 20px;
    padding-bottom: 35px;
    padding-left: 20px;
    border-radius: 30px;
    position: relative;
    z-index: 1;
}

.logo {
    width: 136px;
}

.text {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 34px;
}

.title {
    font-size: 24px;
    color: $text-light;
    width: 80%;
    transform: translate(14%, 0) scaleX(1.28);
    @include font-600;
}

.description {
    font-size: 16px;
    color: $text-light;
    line-height: 25px;
    @include font-400;
}

.input {
    max-width: 510px;
    margin: 25px auto 0;
}

.input input,
.input button {
    border-radius: 4px;
    height: 37px;
}

.input {
    &_future > input:focus {
        border: 1px solid $light_future;
        box-shadow: 0px 0px 7px 0px $light_future inset;
    }

    &_cyberpunk > input:focus {
        border: 1px solid $pink-primary;
        box-shadow: 0px 0px 7px 0px $pink-primary inset;
    }
}

@include tablet {
    .container {
        margin: 100px 0 0;
        padding: 0;
        border-radius: 0;
    }

    .content {
        flex-direction: row;
        gap: 10px;
        border-radius: 0;
        padding-top: 120px;
        padding-right: 20px;
        padding-bottom: 130px;
        padding-left: 20px;
    }

    .logoContainer {
        width: 50%;
        display: flex;
        align-items: center;
        padding-left: 118px;
        box-sizing: border-box;
    }

    .logo {
        width: 248px;
        margin-top: 10px;
    }

    .text {
        width: 80%;
        margin: -12px 0 0 0;
    }

    .input {
        width: 77%;
        margin: 30px 0 0;
    }
}
