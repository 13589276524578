@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.container {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 15px;
    margin-top: 40px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
    @include adaptiveWidth;
}

.pickerContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 26px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    border-radius: 34px;
    &::before,
    &::after {
        opacity: 0;
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        transition: opacity 0.7s ease-in-out;
    }
    &::before {
        background: linear-gradient(180deg, #00c6cc 1.75%, rgb(0, 0, 0, 0) 120%);
        @include mobile-md {
            background: linear-gradient(90.7deg, #00c6cc 1.75%, rgb(0, 0, 0, 0) 99.3%);
        }
    }
    &::after {
        background: linear-gradient(180deg, #9c0cff 1.75%, rgb(0, 0, 0, 0) 120%);
        @include mobile-md {
            background: linear-gradient(90.7deg, #9c0cff 1.75%, rgb(0, 0, 0, 0) 99.3%);
        }
    }
    &_cyberpunk {
        &::before {
            opacity: 0;
        }
        &::after {
            opacity: 1;
        }
    }
    &_future {
        &::before {
            opacity: 1;
        }
        &::after {
            opacity: 0;
        }
    }
}

.text {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: 6px;
    box-sizing: border-box;
    color: $text-light;
    z-index: 2;
}

.title {
    font-size: 24px;
    @include font-600;
}

.list {
    font-size: 16px;
    padding-left: 24px;
    @include font-400;

    li {
        line-height: 32px;
    }
}

.picker {
    grid-area: picker;
}

.docIcon {
    display: none;
    position: absolute;
    bottom: -30px;
    right: 0;
    height: 115px;
    pointer-events: none;

    &_hidden {
        display: none;
    }
}

.advantages {
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.wrapper {
    padding: 0 20px 20px;
}

@include mobile-md {
    .container {
        padding: 40px;
    }
}

@include laptop {
    .container {
        min-height: 510px;
        overflow: hidden;
        padding: 80px;
    }

    .content {
        gap: 50px;
    }

    .pickerContent {
        display: grid;
        grid-template-columns: 0.75fr 1fr;
        grid-template-areas: "picker text";
        padding-top: 122px;
        padding-right: 58px;
        padding-bottom: 84px;
        padding-left: 58px;
    }

    .text {
        padding-top: 2px;
        padding-right: 126px;
        padding-bottom: 10px;
        padding-left: 42px;
        margin: 0;
    }

    .title {
        font-size: 36px;
    }

    .list {
        font-size: 18px;
        padding-left: 32px;
    }

    .docIcon {
        display: block;
        height: 216px;
        right: 16px;
        bottom: 0;

        &_hidden {
            display: block;
        }
    }

    .advantages {
        flex-direction: row;
        gap: 24px;
    }

    .wrapper {
        padding: 0;
    }
}
