@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.slide {
    overflow: hidden;
    width: 100%;
    min-height: 424px;
    height: 100%;
    border-radius: 30px;
    position: relative;
}

.slideBg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-animation: fadeIn 0.7s ease-in forwards;
    animation: fadeIn 0.7s ease-in forwards;
    z-index: -1;
    &_1b1b1b {
        background-color: #1b1b1b;
    }

    &_2b2b2b {
        background-color: #2b2b2b;
    }

    &_494949 {
        background-color: #494949;
    }

    &_7e7e7e {
        background-color: #7e7e7e;
    }

    &_740fff {
        background-color: #740fff;
    }

    &_2f0f9e {
        background-color: #2f0f9e;
    }

    &_4627ff {
        background-color: #4627ff;
    }

    &_d010ff {
        background-color: #d010ff;
    }
}

.slideIconContainer {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background: $text-light;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.slideIcon {
    height: 40%;
}

.slideText {
    display: flex;
    flex-direction: column;
    gap: 26px;
    position: relative;
    z-index: 1;
}

.slideTitle {
    font-size: 24px;
    color: $text-light;
    @include font-600;
    position: relative;
    z-index: 3;
}

.slideDescription {
    font-size: 16px;
    color: $text-light;
    padding-left: 2px;
    line-height: 30px;
    @include font-400;
}

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    box-sizing: border-box;
    padding: 35px 20px;
    z-index: 1;
    &_front {
        transform: perspective(400px) rotateY(0);
    }
    &_back {
        transform: perspective(400px) rotateY(180deg);
    }
    &_future {
        .circle {
            background: $light_future;
        }
    }
    &_cyberpunk {
        .circle {
            background: #ffffffa2;
        }
    }
}

.container_front_isExpanded {
    transform: perspective(400px) rotateY(-180deg);
}

.container_back_isExpanded {
    transform: perspective(400px) rotateY(0deg);
}

.collapseIconContainer {
    position: absolute;
    background: $bg-future;
    width: 38px;
    height: 38px;
    bottom: 20px;
    right: 26px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    z-index: 1;
    &:hover {
        background: rgba($color: $bg-future, $alpha: 0.6);
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
    }
}

.container_back_isExpanded .collapseIconContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .collapseIconItem {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: relative;
        left: 0;
        top: 0;
        transition: height 0.2s ease-in-out;
        &:nth-child(1) {
            transform: translate(0, 0);
        }
        &:nth-child(2) {
            display: block;
        }
        &:nth-child(3) {
            transform: translate(-0, 0) rotate(0);
        }
    }
}

.collapseIconItem {
    width: 2px;
    height: 10px;
    background: $text-light;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: height 0.2s ease-in-out;
    &:nth-child(1) {
        transform: translate(-50%, -50%);
    }
    &:nth-child(2) {
        display: none;
    }
    &:nth-child(3) {
        transform: translate(-50%, -50%) rotate(90deg);
    }
}

.circle {
    position: absolute;
    border-radius: 50%;
    right: 11%;
    bottom: 12%;
    width: 2%;
    padding-bottom: 2%;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 1;
}

.circle.active {
    transition: all 0.5s linear;
    opacity: 0.4;
    transform: scale(140);
}

@include laptop {
    .slide {
        min-height: 414px;
        padding: 50px 20px;
    }

    .slideIconContainer {
        min-width: 108px;
        min-height: 108px;
    }

    .slideText {
        gap: 20px;
    }

    .slideTitle {
        font-size: 32px;
    }

    .slideDescription {
        font-size: 18px;
    }

    .collapseIconContainer {
        width: 50px;
        height: 50px;
        bottom: 28px;
        right: 34px;
    }

    .collapseIconItem {
        width: 2px;
        height: 20px;
    }
}
