@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px 16px 0;
    position: relative;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 26px;
    @include adaptiveWidth;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 3px;
    position: relative;
    z-index: 1;
}

.title {
    font-size: 24px;
    color: $text-light;
    @include font-600;
}

.description {
    font-size: 16px;
    color: $text-light;
    line-height: 32px;
    @include font-400;
}

.advantages {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    position: relative;
    &_cyberpunk {
        & > div:nth-child(1) > div {
            background-color: $darkest-purple;
        }
        & > div:nth-child(2) > div {
            background-color: $personal2_cyberpunk;
        }
        & > div:nth-child(3) > div {
            background-color: $purple;
        }
    }
    &_future {
        & > div:nth-child(1) > div {
            background-color: $personal1_future;
        }
        & > div:nth-child(2) > div {
            background-color: $personal2_future;
        }
        & > div:nth-child(3) > div {
            background-color: $personal3_future;
        }
    }
}

@include tablet {
    .container {
        padding: 100px 80px 0;
    }
}

@include laptop {
    .advantages {
        display: grid;
        grid-template-areas:
            "first first second second second"
            "first first third third third";
    }
    .text {
        padding-left: 8px;
        gap: 21px;
    }

    .title {
        font-size: 36px;
    }

    .description {
        font-size: 18px;
        width: 50%;
    }

    .advantages {
        display: grid;
        grid-template-areas:
            "first first second second second"
            "first first third third third";
    }
}
