@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 45px 16px 0;
}

.content {
    border-radius: 30px;
    background-color: $default-grey;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 26px;
    padding-right: 22px;
    padding-bottom: 26px;
    padding-left: 22px;
    transition: background-color 0.6s ease-in-out;
    @include adaptiveWidth;

    &_cyberpunk {
        background-color: $darkest-purple;
    }
}

.text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    z-index: 1;
}

.title {
    font-size: 24px;
    color: $text-light;
    line-height: 34px;
    @include font-600;
    transition: color 0.6s ease-in-out;
    span {
        color: $cyan-primary;
        transition: color 0.6s ease-in-out;
    }
    &_cyberpunk {
        span {
            color: $yellow-primary;
        }
    }
}

.description {
    font-size: 16px;
    color: $text-light;
    line-height: 32px;
    @include font-400;
}

.image {
    max-width: 100%;
    position: relative;
    z-index: 1;
}

@include tablet {
    .content {
        padding: 100px 80px 30px;
    }
}

@include laptop {
    .content {
        flex-direction: row;
        gap: 110px;
        padding-top: 54px;
        padding-right: 42px;
        padding-bottom: 20px;
        padding-left: 42px;
    }

    .text {
        padding-top: 46px;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 42px;
        box-sizing: border-box;
        gap: 20px;
    }

    .title {
        font-size: 36px;
        line-height: 44px;
        width: 85%;
    }

    .description {
        font-size: 18px;
        width: 85%;
    }

    .image {
        max-width: 45%;
    }
}
