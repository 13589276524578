@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.logoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
}

.logo {
    width: 138px;
    & g g:nth-child(2) {
        transform-origin: 7% 25%;
    }
}

.logo_animation g g:nth-child(2) {
    -webkit-animation: rotate_and_scale 1s alternate 1;
    animation: rotate_and_scale 1s alternate 1;
    transform-origin: 7% 25%;
    transform: rotate(360deg) scale(1);
    transition: all 1s ease-in-out;
}

@-webkit-keyframes rotate_and_scale {
    0% {
        transform: rotate(0) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(0.3);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes rotate_and_scale {
    0%,
    100% {
        transform: rotate(0) scale(1);
    }
    50% {
        transform: rotate(360deg) scale(0.3);
    }
}

.title {
    font-size: 32px;
    color: $text-hero;
    text-align: center;
    transform: scaleX(1.32);
    @include font-100;

    &:not(.title_eng) {
        width: 68%;
    }
}

.folders {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 54px;
    margin-top: 48px;
}

@include tablet {
    .folders {
        width: auto;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 110px;
        gap: 74px;
    }
}

@include laptop {
    .content {
        padding-bottom: 40px;
    }

    .logoContainer {
        height: 135px;
    }

    .logo {
        width: 190px;
    }

    .title {
        font-size: 64px;
        @include font-200;
    }
}
