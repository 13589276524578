@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: rotate(120deg);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 5%;
    &:hover {
        .preview {
            width: 130%;
            height: 130%;
        }
    }
}

.icon {
    position: absolute;
    z-index: 2;
    width: 38px;
    height: 38px;
    transform: rotate(-120deg);
}

.textContainer {
    transform: scale(0.6);
}

.text {
    letter-spacing: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-animation: 5s rotating linear infinite;
    animation: 5s rotating linear infinite;
    fill: white;
    overflow: visible;
    @include font-600;
}

.preview {
    width: 0;
    height: 0;
    position: absolute;
    border-radius: 50%;
    transition: all 0.3s ease-out;
    background-color: rgba(255, 255, 255, 0.5);
}

@include tablet {
    .container {
        left: 4%;
    }
}

@include laptop {
    .textContainer {
        transform: scale(1);
    }

    .icon {
        width: 65px;
        height: 65px;
    }
}

@-webkit-keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}
