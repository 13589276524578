@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    padding: 45px 0 0;
    position: relative;
}

.content {
    min-height: 628px;
    display: flex;
    justify-content: center;
    background-position: center -1px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.staticContent {
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding-top: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    box-sizing: border-box;
    @include adaptiveWidth;
}

.advantage {
    border-radius: 30px;
    background-color: #3d3d3d;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.6s ease-in-out;
    &:first-of-type {
        background-color: #292929;
    }

    &_cyberpunk {
        background-color: $darkest-purple;

        &:first-of-type {
            background-color: $light-blue;
        }
    }

    &:nth-of-type(1) {
        .imageContainer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            padding-top: 32px;
            position: relative;
            z-index: 1;
        }

        img {
            width: 95%;
        }
    }

    &:nth-of-type(2) {
        .imageContainer {
            padding-top: 24px;
            padding-bottom: 36px;
            position: relative;
            z-index: 1;
        }

        img {
            width: 100%;
        }
    }

    &:nth-of-type(3) {
        .imageContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 38px;
            position: relative;
            z-index: 1;
        }

        img {
            width: 40%;
        }
    }
}

.text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 26px;
    padding-right: 20px;
    padding-bottom: 0;
    padding-left: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.title {
    color: $text-light;
    font-size: 24px;
    line-height: 40px;
    @include font-700;
}

.description {
    color: $text-light;
    font-size: 16px;
    line-height: 32px;
    @include font-400;
}

@include tablet {
    .container {
        padding: 100px 80px 0;
        position: relative;
    }
}

@include laptop {
    .content {
        min-height: 850px;
        align-items: flex-start;
        border-radius: 32px;
        background-position: center -1px;
        background-size: cover;
        background-repeat: no-repeat;
        @include adaptiveWidth;
    }

    .staticContent {
        padding-top: 72px;
        padding-right: 0;
        padding-bottom: 72px;
        padding-left: 0;
        display: grid;
        gap: 30px;
        grid-template-areas:
            "first first"
            "second third";
    }

    .advantage {
        border-radius: 34px;

        .text {
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 0;
            padding-left: 0;
        }

        &:nth-of-type(1) {
            grid-area: first;
            padding-top: 40px;
            padding-bottom: 38px;
            padding-left: 72px;
            flex-direction: row;
            align-items: center;
            gap: 60px;

            img {
                width: 100%;
            }
        }

        &:nth-of-type(2) {
            grid-area: second;
            justify-content: space-between;

            .text {
                padding-top: 48px;
                padding-right: 58px;
                padding-bottom: 0;
                padding-left: 84px;
            }
        }

        &:nth-of-type(3) {
            grid-area: third;
            gap: 60px;

            .text {
                padding-top: 48px;
                padding-right: 58px;
                padding-bottom: 0;
                padding-left: 84px;
            }

            .imageContainer {
                width: 100%;
                justify-content: flex-end;
                padding-right: 80px;
                box-sizing: border-box;
            }

            img {
                width: 48%;
            }
        }
    }

    .title {
        font-size: 36px;
        line-height: 44px;
    }

    .description {
        font-size: 18px;
    }
}
