@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.icon {
    width: 24px;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover,
    &_active {
        opacity: 1;
    }
}

.text {
    color: $text-light;
    text-transform: uppercase;
    font-size: 12px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    user-select: none;
    @include font-200;

    &:hover,
    &_active {
        opacity: 1;
    }

    &:first-of-type {
        margin-right: 26px;
    }

    &:last-of-type {
        margin-left: 26px;
    }
}

.switch {
    width: 28px;
    height: 18px;
    border: 2px solid $text-light;
    box-sizing: border-box;
    position: relative;
    padding-top: 2px;
    padding-right: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &_indicator {
        width: 10px;
        height: 10px;
        background-color: $text-light;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 2px;
        transform: translate(0, -50%);
        transition: all 0.3s ease-in-out;

        &_active {
            left: calc(100% - 2px);
            transform: translate(-100%, -50%);
        }
    }
}
