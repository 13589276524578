@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    position: relative;
    padding: 45px 16px 25px;
}

.text {
    display: flex;
    flex-direction: column;
    gap: 9px;
    padding-top: 0;
    padding-bottom: 0;
    color: $text-light;
    z-index: 1;
}

.title {
    font-size: 24px;
    @include font-600;
}

.description {
    width: 90%;
    font-size: 16px;
    line-height: 32px;
    @include font-400;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

@include tablet {
    .container {
        gap: 20px;
        padding: 100px 80px 25px;
    }
}

@include laptop {
    .text {
        gap: 20px;
    }

    .title {
        font-size: 36px;
    }

    .description {
        width: 60%;
        font-size: 18px;
    }
}
