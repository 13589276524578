@import "./variables.scss";

// FONTS
@mixin font-100 {
    font-family: "Inter-100";
}

@mixin font-200 {
    font-family: "Inter-200";
}

@mixin font-300 {
    font-family: "Inter-300";
}

@mixin font-400 {
    font-family: "Inter-400";
}

@mixin font-500 {
    font-family: "Inter-500";
}

@mixin font-600 {
    font-family: "Inter-600";
}

@mixin font-700 {
    font-family: "Inter-700";
}

@mixin font-800 {
    font-family: "Inter-800";
}

@mixin font-900 {
    font-family: "Inter-900";
}

// BREAK POINTS
@mixin mobile-sm {
    @media screen and (min-width: $mobile-sm-width) {
        @content;
    }
}

@mixin mobile-base {
    @media screen and (min-width: $mobile-base-width) {
        @content;
    }
}

@mixin mobile-md {
    @media screen and (min-width: $mobile-md-width) {
        @content;
    }
}

@mixin mobile-lg {
    @media screen and (min-width: $mobile-lg-width) {
        @content;
    }
}

@mixin tablet {
    @media screen and (min-width: $tablet-width) {
        @content;
    }
}

@mixin laptop {
    @media screen and (min-width: $laptop-width) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-width) {
        @content;
    }
}

// OTHER
@mixin adaptiveWidth {
    width: 100%;
    max-width: $mobile-lg-width;

    @include mobile-lg {
        & {
            max-width: 750px;
        }
    }

    @include laptop {
        & {
            max-width: 980px;
        }
    }

    @include laptop {
        & {
            max-width: 1024px;
        }
    }

    @include laptop {
        & {
            max-width: 1920px;
        }
    }
}
